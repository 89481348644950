import React from 'react';
import { LinkList } from '@aph/components/common/link-list/link-list.component';
import { PROMOTED_LINKS } from '@aph/components/common/link-list/promoted-links';
import { parseEventTree, sendMainNavigationEvent } from '@aph/components/gtm/events/navigation-gtm';
import { ArticleCategoryNavigation } from './article-category-navigation/article-category-navigation.component';

export const SidebarWithCategoryNavigation = () => {
  return (
    <nav className="flex flex-col gap-y-3">
      <LinkList
        links={PROMOTED_LINKS}
        variant="primary"
        onLinkClick={(target) => sendMainNavigationEvent(parseEventTree(target.href))}
      />
      <ArticleCategoryNavigation />
    </nav>
  );
};
