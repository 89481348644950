import { Box, Stack } from '@mui/material';
import { QueryClient, dehydrate } from '@tanstack/react-query';
import type { GetServerSideProps, InferGetServerSidePropsType, NextPage } from 'next';
import { PageLayout } from '@aph/components/layouts/page-layout.component';
import { useGetSEOPropsWithFallbacks } from '@aph/hooks/use-get-seo-props-with-fallbacks/use-get-seo-props-with-fallbacks';
import { isErrorWithStatus } from '@aph/utilities/errors/is-error-with-status';
import { withPageErrorHandler } from '@aph/utilities/with-page-error-handler';
import { ArticleGroups } from '~/articles/components/article-groups/article-groups';
import { SidebarWithCategoryNavigation } from '~/articles/components/sidebar-with-category-navigation';
import { useGetCategoryNavigation } from '~/articles/hooks/use-get-category-navigation';
import { useGetPageArticleGroupsQuery } from '~/articles/hooks/use-get-page-article-groups/use-get-page-article-groups';
import { ContentApiClient } from '~/contentful/api/ContentApiClient';
import { ContentRenderer } from '~/contentful/components/content-renderer/content-renderer';
import { InfoBar } from '~/contentful/components/info-bar/info-bar';
import {
  ProductPanel,
  type ProductPanelProps,
} from '~/contentful/components/product-panel/product-panel';
import { HeroBanner } from '~/contentful/components/start/hero-banner';
import { HeroCampaign } from '~/contentful/components/start/hero-campaign';
import type { IStartpage, IStartpageFields } from '~/contentful/types';
import { mapContentfulSEOToNextSEO } from '~/contentful/utilities/next-seo-from-contentful';
import { logger } from '~/logging';

const PAGE_ID = 'start-page';

const StartPage: NextPage<InferGetServerSidePropsType<typeof getServerSideProps>> = ({
  contentFulFields,
}) => {
  const { seo, heroBanner, heroCampaign, pageContent, infoBar } = contentFulFields.startPage || {};

  const seoProps = useGetSEOPropsWithFallbacks({
    pageTitle: 'Startsida',
    seoProps: mapContentfulSEOToNextSEO(seo?.fields),
  });

  if (!contentFulFields.startPage) {
    return (
      <PageLayout wave={false} seo={seoProps} sidebar={<SidebarWithCategoryNavigation />}>
        <ArticleGroups
          queryBy={{
            pageId: PAGE_ID,
          }}
        />
      </PageLayout>
    );
  }

  return (
    <PageLayout
      seo={seoProps}
      sidebar={<SidebarWithCategoryNavigation />}
      hero={
        heroBanner && heroCampaign ? (
          <Stack direction={{ xs: 'column', md: 'row' }} gap={2}>
            <Box width={{ xs: '100%', md: '75.7%' }}>
              <HeroBanner heroBanner={heroBanner} />
            </Box>
            <Box width={{ xs: '100%', md: '24.3%' }}>
              <HeroCampaign heroCampaign={heroCampaign} />
            </Box>
          </Stack>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <></>
        )
      }
      top={<InfoBar data={infoBar?.fields} />}
    >
      {pageContent && (
        <Stack gap={5}>
          <ContentRenderer
            content={pageContent}
            mappings={{
              productPanel: StartPageProductPanel,
            }}
          />
        </Stack>
      )}
    </PageLayout>
  );
};

const StartPageProductPanel = (props: ProductPanelProps) => (
  <ProductPanel {...props} pageId={PAGE_ID} />
);

export const getServerSideProps = (async ({ res, req }) => {
  res.setHeader('Cache-Control', 'public, s-maxage=20, stale-while-revalidate=60');

  try {
    const queryClient = new QueryClient();
    const contentApiClient = new ContentApiClient({ headers: req.headers });

    // these queries can be fetched in parallel
    const getServerSideData = Promise.all([
      // we place this one first since this is the one we are interested in getting the results for
      contentApiClient
        .getEntityBySlug<IStartpage>({
          slug: '/',
          contentType: 'startpage',
        })
        .then((data) => data.fields)
        .catch((error) => {
          logger.error(error, `Error in contentApiClient in getServerSideProps with slug "/"`);
          return null;
        }),

      // pre-fetch the article groups for the start page
      queryClient.prefetchQuery({
        queryKey: useGetPageArticleGroupsQuery.getKey({ pageId: 'start-page' }),
        queryFn: () =>
          useGetPageArticleGroupsQuery.fetcher(
            { pageId: 'start-page' },
            { meta: { headers: req.headers } },
          ),
      }),

      // pre-fetch the product category navigation for the start page
      queryClient.prefetchQuery({
        queryKey: useGetCategoryNavigation.getKey({ slug: 'startpage' }),
        queryFn: () =>
          useGetCategoryNavigation.fetcher(
            { slug: 'produkter', levels: 1 },
            { meta: { headers: req.headers } },
          ),
      }),
    ]);

    const [startPage] = await getServerSideData;

    return {
      props: {
        contentFulFields: { startPage },
        trpcState: dehydrate(queryClient),
      },
    };
  } catch (error) {
    logger.error(error, `Error in getServerSideProps with slug "/"`);
    if (isErrorWithStatus(error) && error.status >= 500) {
      res.statusCode = 503;
      return {
        props: {
          contentFulFields: { startPage: null as unknown as IStartpageFields },
          error: { status: 503 },
        },
      };
    }
  }

  return { notFound: true };
}) satisfies GetServerSideProps<{ contentFulFields: { startPage: IStartpageFields | null } }>;

export default withPageErrorHandler(StartPage);
